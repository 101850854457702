import React from "react"

const NewPage = () => {
  return (
    <div>
      <h1>New Page</h1>
    </div>
  )
}

export default NewPage
